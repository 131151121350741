<template>
  <div>
    <div class="search-box">
      <div class="search-box-params">
        <!-- <TextInput
          style="margin-left: 50px"
          @returnVal="getKeyToValue"
          v-model="params.enterpriseName"
          :optionConfig="{
            value: 'dictKey',
            label: 'dictValue',
          }"
          :config="{
            type: 'select',
            option: dictList.enterpriseNameList,
            modelTextKey: 'enterpriseName',
            label: '企业名称',
          }"
          labelPosition="left"
        ></TextInput> -->
        <TextInput
          style="margin-left: 50px"
          @handleSelectQuerySearchAsync="handleSelectSearchAsync"
          :querySearchAsync="querySearchAsync"
          v-model="params.enterpriseName"
          :config="{
            type: 'searchInput',
            label: '企业名称',
          }"
          labelPosition="left"
        >
        </TextInput>
        <TextInput
          style="margin-left: 50px"
          v-model="params.areaDist"
          @returnVal="getKeyToValue"
          :form="params"
          :config="{
            type: 'distpicker',
            regionKeys: ['province', 'city'],
            modelTextKey: 'areaDist',
            label: '地区',
          }"
          labelPosition="left"
        ></TextInput>
        <TextInput
          style="margin-left: 50px"
          v-model="params.residenceTime"
          @returnVal="getKeyToValue($event, params)"
          :form="params"
          :config="{
            type: 'daterange',
            daterangeKeys: ['residenceTimeStart', 'residenceTimeEnd'],
            // daterangeFormats: ['YYYY-MM-DD', 'YYYY-MM-DD'],
            modelTextKey: 'residenceTime',
            label: '进驻时间',
          }"
          labelPosition="left"
        >
        </TextInput>
      </div>
      <div class="search-box-btns">
        <el-button type="primary" @click="getData">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </div>
    </div>
    <!-- 数据统计 -->
    <div class="card">
      <div class="card-item" v-for="(item, index) in cardList">
        <div class="card-item-left">
          <div>{{ item.title }}</div>
          <div class="card-item-left-data">
            {{ addThousandSeparator(initData[item.key] || 0) }}
          </div>
          <!-- <div v-if="item.path" class="card-item-left-path">详情 ></div> -->
        </div>
        <div class="card-item-right">
          <img style="width: 54xp; height: 54px" :src="item.icon" />
        </div>
      </div>
    </div>
    <SimpleTable ref="table" :tableProps="tableProps" :queryFun="queryFun">
    </SimpleTable>
  </div>
</template>

<script>
import SimpleTable from "../components/SimpleTable";
import { addThousandSeparator } from "@/utils/dealNumber";
import TextInput from "../components/TextInput.vue";
import {
  getEnterpriseList,
  getEnterpriseDataBoard,
  getPolicyListByBlurMan,
} from "@/api/safeDuty.js";
export default {
  components: { SimpleTable, TextInput },
  data() {
    return {
      //
      addThousandSeparator: addThousandSeparator,
      //
      dictList: { enterpriseNameList: [] },
      initData: {},
      params: {
        city: "",
        enterpriseName: "",
        province: "",
        residenceTimeEnd: "",
        residenceTimeStart: "",
        residenceTime: [],
        areaDist: [],
      },
      tableData: [],
      cardList: [
        {
          title: "签约企业总数",
          key: "enterprisesTotalNumber",
          icon: require("@/assets/images/safeDuty/qyqyzs.png"),
          tips: "",
          path: "1",
        },
        {
          title: "签约总保费",
          key: "totalPremium",
          icon: require("@/assets/images/safeDuty/qyzbf.png"),
          tips: "",
          path: "2",
        },
        {
          title: "风险减量支出",
          key: "riskDisburse",
          icon: require("@/assets/images/safeDuty/fxjlzc.png"),
          tips: "",
          path: "3",
        },
        {
          title: "理赔案件支出",
          key: "claimsDisburse",
          icon: require("@/assets/images/safeDuty/lpajzc.png"),
          tips: "",
          path: "",
        },
        {
          title: "满期赔付率",
          key: "maturityLossRatio",
          icon: require("@/assets/images/safeDuty/mqpfl.png"),
          tips: "",
          path: "",
        },
        {
          title: "综合支出率",
          key: "combinedRateExpenditure",
          icon: require("@/assets/images/safeDuty/zhzcl.png"),
          tips: "",
          path: "",
        },
      ],
      tableProps: {
        isSelection: false,
        isColumnSet: false,
        isHiddenSet: false,
        isSearch: false,
        isRefresh: false,
        isFuzzyQuery: false,
        border: true,
        currentPageKey: "current",
        currentSizeKey: "size",
        height: 500,
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "insuredName",
            label: "企业名称",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "areaProvince",
            label: "省份",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "areaCity",
            label: "城市",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "safetyInsurancePoliciesNumber",
            label: "安责险保单数",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "securityTotalPremium",
            label: "安责总险保费",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "trainingNumber",
            label: "培训次数",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "claimsNumber",
            label: "理赔次数",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "claimTotalAmount",
            label: "理赔总金额",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "createTime",
            label: "进驻时间",
            width: "",
            type: "",
            isShow: true,
          },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    getKeyToValue(res, res2) {
      if (res.comType == "distpicker") {
        Object.keys(res.data).forEach((key) => {
          this.params[key] = res.data[key];
        });
      } else if (res.comType == "daterange") {
        res.data.daterangeKeys.forEach((key, index) => {
          this.params[key] = res.data.dates[key];
        });
      }
    },
    init() {
      getEnterpriseDataBoard(this.params).then((res) => {
        this.initData = res.data;
      });
    },
    reset() {
      this.params = {
        city: "",
        enterpriseName: "",
        province: "",
        residenceTimeEnd: "",
        residenceTimeStart: "",
        residenceTime: [],
        areaDist: [],
      };
      //console.log(this.params);
      this.getData();
    },
    getData() {
      this.$refs.table.getData();
      this.init();
    },
    async queryFun(queryParams) {
      let res = await getEnterpriseList({ ...queryParams, ...this.params });
      return {
        localData: res.data.records || [{}],
        total: res.data.total,
      };
    },
    querySearchAsync(queryString, cb) {
      if (!queryString) {
        cb([]);
        return;
      }
      getPolicyListByBlurMan({ insuredName: queryString }).then(
        (res) => {
          let results = res.data.insurancePolicies.map((item) => {
            return {
              ...item,
              value: item.insuredName,
            };
          });
          cb(results);
        },
        () => {
          cb([]);
        }
      );
    },
    handleSelectSearchAsync(e) {
      this.params.enterpriseName = e.value;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .action-container {
  padding: 0 !important;
}
.card {
  display: flex;
  overflow: auto;
  justify-content: space-between;
  margin-bottom: 10px;
  &-item {
    width: 270px;
    height: 125px;
    background-color: #ffffff;
    border-radius: 10px 10px 10px 10px;
    opacity: 0.8;
    padding: 16px;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      flex-direction: column;
      &-data {
        font-weight: bold;
        font-size: 24px;
        margin-top: 10px;
      }
      &-path {
        margin-top: 8px;
        color: #377dff;
        cursor: pointer;
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
  }
}
.search-box {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 10px;
  padding: 10px 0 16px 0;
  align-items: flex-end;
  &-params {
    display: flex;
    align-items: center;
  }
  &-btns {
    margin-right: 12px;
  }
}
</style>
